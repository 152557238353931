.padding-24 {
  padding: 24px;
}

.profile-picture {
  box-sizing: border-box;
}

.space-4,
[class*=vspace-4] {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 4px 0 3px;
}

.space {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
}

.profile-info-name {
  text-align: right;
  padding: 6px 10px 6px 4px;
  color: #667E99;
  border-top: 1px dotted #D5E4F1;
  display: flex;
  align-items: center;

}

.profile-info-value {
  padding: 6px 10px 0 4px;
  border-top: 1px dotted #D5E4F1;
  display: flex;
  align-items: center;
}


.label-flag {
  display: inline;
  padding: 3px 10px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #9585bf;


  &.arrowed-in-right {
    margin-right: 5px;
  }

  &.arrowed-in-right,
  &.arrowed-right { //z-index: 1;

    position: relative;

    &:after {
      display: inline-block;
      content: "";
      position: absolute;
      top: 0;
      z-index: -1;
      border: 1px solid transparent;
      border-left-color: transparent;
      right: -15px;
      border-width: 10px 8px;
      border-right-color: #9585bf;
      border-top-color: #9585bf;
      border-bottom-color: #9585bf;
      height: 100%;
      width: 15px;
      transform: rotate(0deg) scaleX(-1);

    }
  }

  font-size: 12px;
}


.hr-dotted, .hr.dotted {
  border-style: dotted;
}

.hr-8, .hr8 {
  margin: 8px 0;
}

.hr {
  display: block;
  height: 0;
  overflow: hidden;
  font-size: 0;
  border-width: 1px 0 0;
  border-top: 1px solid #E3E3E3;
  margin: 12px 0;
  border-top-color: rgba(0, 0, 0, .11);
}

.bigger-110 {
  font-size: 110% !important;
}


