

.modal-content-full{
  .modal-content {
    width: 100%;
  }
}

.modal-datepicker > .modal-content {
  width: unset;
}

.modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  //border-radius: 3px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
  background-clip: padding-box;
  outline: 0;
  border-radius: 0px !important;
  //width: unset;

  .modal-header {
    border-bottom: 1px dashed rgba(0, 0, 0, .1);
    border-radius: 0 !important;

    &:not(header) {
      border-bottom: 1px dashed rgba(0, 0, 0, .1);
    }

    header {
      margin: 0px 14px;
      border-bottom: none;
    }


    .modal-title {
      margin: 0;
      line-height: 1.42857143;
    }

  }


  header {
    width: 100%;
    display: block;
    border-bottom: none;
    padding: 8px 0;
    font-size: 20px;
    font-weight: 300;
    color: #232323;
    margin: 10px 0 20px;
    border-bottom: 1px dashed rgba(0, 0, 0, .1);
  }


  .close {
    position: absolute;
    margin: 0;
    right: 8px;
    top: 16px;
    cursor: pointer;
    float: right;
    font-size: 19.5px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .2;
    filter: alpha(opacity=20);
    border: none;

    &:before {
      content: '×';
      position: absolute;
      right: 8px;
      top: 4px;
    }
  }


  fieldset {
    display: block;
    padding-left: 0;
    padding-right: 0;
    border: none;
    background: #ffffff;
    position: relative;
  }

}

.modal-dialog {
  display: flex;
  flex-grow: 1;
}

.min-height-80 {
  min-height: 80% !important;
}

.min-width-80 {
  min-width: 80% !important;
}

@include media-breakpoint-up(sm) {

  .modal-300-px {
    max-width: 300px;
  }


  .modal-400-px {
    max-width: 400px;
  }

  .modal-500-px {
    max-width: 500px;
  }

  .modal-600-px {
    max-width: 600px;
  }

}

@include media-breakpoint-between(sm, md) {

  .modal-dialog {
    max-width: 90%;
  }

}

@include media-breakpoint-up(md) {

  .modal-700-px {
    max-width: 700px;
  }

  .modal-800-px {
    max-width: 800px;
  }

  .modal-900-px {
    max-width: 900px;
  }
}

.modal-header-primary,
.modal-header-success,
.modal-header-warning,
.modal-header-danger,
.modal-header-info {
  header {
    color: #fff;
  }
}

//https://bootsnipp.com/snippets/dzvv
.modal-header-primary {
  color: #fff;
  border-bottom: 1px solid #eee;
  background-color: #428bca;

}

.modal-header-success {
  color: #fff;
  border-bottom: 1px solid #eee;
  background-color: #5cb85c;
}

.modal-header-warning {
  color: #fff;
  border-bottom: 1px solid #eee;
  background-color: #f0ad4e;
}

.modal-header-danger {
  color: #fff;
  border-bottom: 1px solid #eee;
  background-color: #d9534f;
}

.modal-header-info {
  color: #fff;
  border-bottom: 1px solid #eee;
  background-color: #5bc0de;
}

