.btn-selected {
  border-color: #f35b3f !important;;
  box-shadow: $whiteframe-shadow-2dp;
}

.btn-close.close {
  background: transparent;
  justify-content: center;
  align-items: center;
  display: flex;
}
