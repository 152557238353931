@import "variables";

@mixin scrollbar() {

  $scrollbarWidth:10px;

  //<https://css-tricks.com/custom-scrollbars-in-webkit/>
  &::-webkit-scrollbar {
    width: $scrollbarWidth;
    height: $scrollbarWidth;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track-piece {
    background-color: lighten(lightgray, 10%);

  }

  &::-webkit-scrollbar-thumb {
    height: $scrollbarWidth;
    background-color: $gray-400;
    &:hover {
      background-color: $gray-500;
    }

  }

  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-corner,
  &::-webkit-resizer {
    display: none;
  }

}


