@import '/src/scss/variables';



.bgc-white {
    background-color: #fff;
}



[class*=brc-h-] {
    transition: border-color .15s;
}

.text-dark-m1 {
    color: #323337 !important;
}


.bgc-warning-l3,
.bgc-h-warning-l3:hover {
    background-color: lighten($primary, 80%);
}

.brc-warning-m1,
.brc-h-warning-m1:hover {
    border-color: $primary;
}


