
tabset,
.tab-content,
.tab-content > .active {
  display: flex;
  flex-direction: column;
  height: 100%;
}

tabset {
  .nav-tabs {
    display: none;
    .nav-item {
      background: #fff;
    }

    .nav-link {
      &:not(.active) {
        border-bottom: thin solid #cccccc;
      }

      &.active {
        border-bottom: none;
      }
    }

  }

  .tab-content{
    border: none;
  }

}


