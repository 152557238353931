fieldset {
  display: block;
  padding: 25px 10px 0px;
  border: none;
  background: rgba(255, 255, 255, .9);
  position: relative;
}

fieldset + fieldset {
  border-top: 1px solid rgba(0, 0, 0, .1);
}
