// should contain all variables

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";


$primary: #23410a;



$theme-colors: (
  primary:#23410a
);


@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value,
      // modify
      $hover-background: lighten($value, 5%),
      $hover-border: lighten($value, 10%),
      $active-background: lighten($value, 10%),
      $active-border: lighten($value, 12.5%) // /modify
    );
  }
}


@import "./material-design/variables";
@import "bourbon";