.square-container {
    position: relative;
    width: 100%;
  
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  
  }
  