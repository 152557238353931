.cdk-overlay-container {
  //position: absolute !important;

  // this is because of the dropdown menu's over a modal
  z-index: 1051;

  //&.sidebar-contextmenu {
  //  z-index: 1020;
  //
  //}
  //
  //
  //.dropdown-item {
  //  > span:first-child {
  //    padding-left: 34px;
  //  }
  //
  //  fa-icon + span {
  //    padding-left: unset;
  //  }
  //
  //}

  .cdk-overlay-pane.cdk-panel-top {
    .arrow {
      top: 100%;
      transform: translateX(-50%) translateY(0%);

      &::after {
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
      }
    }

  }

  &.driver-fix-stacking {
    //z-index: 9999999 !important;
  }

  .cdk-overlay-pane:not(.cdk-panel-top) {
    .arrow {
      &::after {
        transform: translateX(-50%) translateY(50%) rotate(45deg);
      }
    }
  }


}

.ctx-mw-250 {
  width: 250px;
}

.text-center {
  .mat-simple-snackbar {
    justify-content: center !important;
  }
}


.mat-dialog-padding-0 {
  mat-dialog-container {
    padding: 0 !important;
  }
}

.mat-shadow-none {
  mat-dialog-container {
    box-shadow: none !important;
  }
}
