@import "/src/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";


@include media-breakpoint-down(sm) {
  .dialog-mobile-responsive {
    max-width: 95vw !important;
    position: absolute !important;
    top: 5%;
  }
}
