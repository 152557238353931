.ribbon {

  position: relative;
  z-index: 0;

  .ribbon-content {
    font-size: 12px;

    background: #ba89b6;
    color: #fff;
    text-align: center;
    padding: 0px 10px;


    &:before,
    &:after {
      content: "";
      position: absolute;
      display: block;
      border-style: solid;
      border-color: darken(#ba89b6, 20%) transparent transparent transparent;
      bottom: -5px;
    }

    &:before {
      left: 0;
      border-width: 5px 0 0 5px;
    }

    &:after {
      right: 0;
      border-width: 5px 5px 0 0;
    }

  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    display: block;
    bottom: -5px;
    border: 9px solid darken(#ba89b6, 10%);
    z-index: -1;
  }

  &:before {
    left: -10px;
    border-right-width: 5px;
    border-left-color: transparent !important;
  }

  &:after {
    right: -10px;
    border-left-width: 5px;
    border-right-color: transparent !important;
  }


  &.tangerine {
    .ribbon-content {
      background: $tangerine;

      &:before,
      &:after {
        border-color: darken($tangerine, 20%) transparent transparent transparent;
      }
    }

    &:before,
    &:after {
      border-color: darken($tangerine, 10%);
    }

  }


  &.primary {
    .ribbon-content {
      background: #007bff;

      &:before,
      &:after {
        border-color: darken(#007bff, 20%) transparent transparent transparent;
      }
    }

    &:before,
    &:after {
      border-color: darken(#007bff, 10%);
    }

  }


  &.secondary {
    .ribbon-content {
      background: #6c757d;

      &:before,
      &:after {
        border-color: darken(#6c757d, 20%) transparent transparent transparent;
      }
    }

    &:before,
    &:after {
      border-color: darken(#6c757d, 10%);
    }

  }

  &.success {
    .ribbon-content {
      background: #28a745;

      &:before,
      &:after {
        border-color: darken(#28a745, 20%) transparent transparent transparent;
      }
    }

    &:before,
    &:after {
      border-color: darken(#28a745, 10%);
    }

  }


}
