mat-accordion {

  &.mat-accordion {
    > .mat-expansion-panel-spacing:last-child,
    > *:last-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing {
      margin-top: 16px !important;
    }

    > .mat-expansion-panel-spacing:first-child,
    > *:first-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing {
      margin-bottom: 16px !important;
    }

    .mat-expansion-panel:last-of-type {
      border-radius: 0px;
    }


    .mat-expansion-panel-header-title,
    .mat-expansion-panel-header-description {
      margin: 0px;
    }


  }


  &.mat-headers-align .mat-expansion-panel-header-description {
    justify-content: flex-end;
    align-items: center;
  }


  .mat-action-row {
    padding: 8px 8px;
  }

}
