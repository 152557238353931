.dz-message {
  color: $eq-charcoal;
  $small-screen-font: 17px;
  $screen-font: 25px;
  font-family: $font-family-sans-serif;

  font-size: $small-screen-font;
  .text {
    font-size: $small-screen-font + 1;

  }

  @include media-breakpoint-up('sm') {
    font-size: $screen-font;
    .dz-text {
      font-size: $screen-font + 1;

    }

  }



}
