@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

.ag-theme-alpine .ag-ltr {
  .ag-header-cell,
  .ag-cell {
    border-right: 1px solid #d9dcde;
    padding: 0;
  }
}



