
//.badge.badge-primary {
//  color: #fff;
//  background-color: #5899ca;
//  border-color: #5899ca;
//}

.badge.badge-pink {
  color: #fff;
  background-color: #d65c8b;
  border-color: #d65c8b;
}

//.badge {
//  font-size: 75%;
//  font-weight: 600;
//  padding: 0.25rem 0.5rem 0.275rem;
//  line-height: 1.175;
//  position: relative;
//}

//.badge {
//  padding: -0.8em 0.6em;
//  font-size: 12px;
//  color: #fff;
//  text-align: center;
//  white-space: nowrap;
//  vertical-align: baseline;
//  //border-radius: 0;
//  text-shadow: none;
//  font-weight: 400;
//  display: inline-block;
//  background-color: #abbac3;
//  line-height: 1.15;
//  height: 20px;
//  border-radius: 0.125rem;
//}
//


.badge.arrowed, .badge.arrowed-in {
  position: relative;
  z-index: 1;
}


.badge.arrowed {
  margin-left: 5px;
  top: -1.5px;
}


.badge.arrowed-in:before,
.badge.arrowed:before {
  display: inline-block;
  content: "";
  position: absolute;
  top: 0;
  z-index: -1;
  border: 1px solid transparent;
  border-right-color: inherit;
}


.badge.arrowed:before {
  left: -10px;
  border-width: 10px 5px;
}
