.navbar-expand-lg {
  .navbar-nav {
    flex-direction: row;

    .dropdown-menu {
      position: absolute;
    }

    a {
      cursor: pointer;
    }
  }
}


.header-border {
  position: relative;
  //z-index: 1021;
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #e5e5e5;
  }
}


/* Dropdown
========================*/
.dropdown-toggle {
  li a {
    line-height: $line-height-base;
  }

  .caret {
    &:before {
      content: 'arrow_drop_down';
      font-family: 'Material Icons';
      font-feature-settings: 'liga';
      vertical-align: middle;
      font-size: 16px;
      width: 1em;
    }
  }
}

.dropdown-menu {
  .open > &,
  .show > & {
    display: block;
  }

  &.dropdown-left {
    left: auto !important;
    right: 0;
  }
}

.dropdown-menu > .selected > a,
.dropdown-menu > .active > a {
  &, &:hover, &:focus {
    background: $primary;
    color: #fff;
    outline: none;
  }
}

.dropdown-item {
  @include padding(em(5, $font-size-base-px) null);
}
