.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}


#pace-progress-container {
  position: absolute;
  width: 100%;
  z-index: 1;

  .pace {

    .pace-progress {
      background: #29d;
      z-index: 2000;
      top: 0;
      right: 100%;
      width: 100%;
      height: 3px;
    }
  }


}
