$enable-negative-margins: true !default;

@import "scss/variables";
@import "bootstrap/scss/bootstrap";
@import "balloon-css/src/balloon";
@import "scss/index";





html,
body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}