.pointer-events-none {
  pointer-events: none !important;
}


.focus-outline-none {
  &:focus {
    outline: none;
  }
}


.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}
