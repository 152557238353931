.alert-info {
  border-color: #9cb4c5;
}

.alert {
  border-width: 0;
  border-left-width: 5px;
  border-radius: 0;
}

.alert-error,
.alert-danger {
  border-color: #953b39;
  color: #fff;
  background-color: #c26565;
  text-shadow: none;
}
