@import "variables";
@import "base";
@import "material-design";
@import "scrollbar";
@import "typography";
@import "fonts";
@import "ag-grid";
@import "helpers";
@import "cards";
@import "colors";
//override
@import "balloon";
@import "ngx-bootstrap/datepicker/bs-datepicker.scss";
@import "../vendors/pacejs/minimal";
.c-value {
  padding: 6px 10px 0 4px;
}

.c-label,
.c-value {
  border-top: 1px dotted #d5e4f1;

  display: flex;
  align-items: center;
}

.c-label {
  padding: 6px 10px 6px 4px;
  color: #667e99;
}
