tabset {
  width: 100%;

  ul.nav-tabs {
    padding-left: 18px;

  }

  li.nav-item > a.nav-link,
  li.nav-item > a.nav-link:focus {
    border-radius: 0 !important;
    background-color: #F9F9F9;
    color: #999;
    margin-right: -1px;
    line-height: 18px;
    position: relative;
    border: 1px solid #c5d0dc;
    text-decoration: none !important;

    &.active {
      color: #576373;
      border-color: #c5d0dc #c5d0dc transparent;
      border-top: 2px solid #4c8fbd;
      background-color: #FFF;
      z-index: 1;
      line-height: 18px;
      margin-top: -1px;
      box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, .15);
    }

    &:not(.active) {
      border-bottom: none;

      &:hover {
        border-color: #ddd;
        background-color: #fff;
      }
    }

  }

  li.nav-item > a.nav-link {
    padding: 7px 12px 8px;
  }

}
